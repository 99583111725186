@font-face {
  font-family: ProximaNovaRegular;
  src: url(./assets/fonts/ProximaNova-Regular.otf);
}

@font-face {
  font-family: ProximaNovaBold;
  src: url(./assets/fonts/ProximaNova-Bold.otf);
}

@font-face {
  font-family: ProximaNovaSemibold;
  src: url(./assets/fonts/ProximaNova-Semibold.otf);
}

@font-face {
  font-family: Heaters;
  src: url(./assets/fonts/Heaters.otf);
}


.text{
  font-size: 64px;
  font-family: ProximaNovaRegular !important;
}