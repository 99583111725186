@import '../utils/styles.css';

.save-pop-up {
    position: fixed; 
    margin: 0px; 
    padding: 0px; 
    top: 50%;
    left: 50%;
    overflow: auto;
    z-index: 1000;
    transform: translate(-50%, -50%);
    min-width: 50vw; 
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.save-pop-up-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--BLUE-SHADE-6);
    border: 4px solid var(--CLUBBIE-YELLOW);
    border-radius: 8px;
}

.saved-design-button-container {
    width: 50%;
}

.saved-design-msg {
    font-size: 2rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    color: var(--LIGHT-BLUE);
    text-align: center;
}

.save-pop-up-stage {
    height: 300px;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .saved-design-button-container {
        width: 100%;
    }
}

