:root {
    /* colors */
   --CLUBBIE-WHITE: #FEFCEB;
   --CLUBBIE-YELLOW: #E7BA19;
   --LIGHT-BLUE: #5FC4EE;
   --CLUBBIE-BLACK:  #15104D;
   --DARK-BLUE:  #000F1F;
   --SHADED-TEXT:  #B4C4D4;
   --BUTTON-WITH-ICON-BGD:  #082441;
   --LIMITER-BACKGROUND:  #8885AC;
   --MODAL-BACKDROP:  rgba(0,15,31,0.8);
   --SWITCH-TRACK-INACTIVE:  #272D39;
   --DARK-GRAY:  #22302F;
   --BRIGHT-BLUE:  #00E0FF;
   --CORAL-RED:  #FF7B7B;
   --SUCCESS:  #8ae190;
   --APP-BACKGROUND:  #000F1F;
   --DDARKER-BLUE:  #5FC3ED;
   --BLUE-LINK:  #79A5D3;
   --PRIMARY:  #5FC4EE;
   --MODAL-BACKGROUND:  #000F1F;
   --PLAIN-WHITE:  #FFF;
   --LIGHT-BLACK:  #204C63;
   --DARK-BLUE-ICON:  #204C63;
   --FULLSCREEN:  rgba(0,15,31,0.8);
   --WARNING:  #E7BA19;
   --WELCOME-SCREEN-BACKGROUND:  #011A38;
   --BLUE-SHADE-1:  #1F374E;
   --BLUE-SHADE-2:  #31577A;
   --BLUE-SHADE-3:  #4C9DBE;
   --BLUE-SHADE-4:  #5FC4EE;
   --BLUE-SHADE-5: #082441aa;
   --BLUE-SHADE-6: #082441ee;
   --WARNING:  #E1CB00;
   --TOGGLE-BACKGROUND:  #7676801F; 

   /* fonts */
   --PROXIMA-NOVA-REGULAR: ProximaNovaRegular, sans-serif;
   --PROXIMA-NOVA-SEMIBOLD: ProximaNovaSemibold, sans-serif;
   --PROXIMA-NOVA-BOLD: ProximaNovaBold, sans-serif;
   --HEATERS: Heaters;
   --COCOGOOSE: Cocogoose;
}