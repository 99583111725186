@import '../utils/styles.css';


.saved-designs-page {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--APP-BACKGROUND);
}

.saved-designs {
    display: flex;
    justify-content: center;
    height: 90%;
    padding-top: 10px;
    overflow-y: auto;
}

.saved-design {
    display: flex;
    margin-bottom: 35px;
}

.saved-designs-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.nothing-saved-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--CLUBBIE-WHITE);
    font-size: 2.5rem;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
}


