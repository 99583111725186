@import '../../utils/styles.css';

.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: var(--APP-BACKGROUND);
    padding-top: 37px
}

.welcome-text-box-email-register {
    margin-top: 40px;
}

.button-container-email-register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.register-button {
    background-color: var(--LIGHT-BLUE) !important;
    border: 0;
    border-radius: 25px !important;
    height: 50px;
    width: 350px;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    color: var(--CLUBBIE-BLACK) !important;
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
}

.register-button:hover {
    opacity: 0.5;
}

.email-input {
    background-color: transparent !important;
    border: 1px solid var(--CLUBBIE-WHITE) !important;
    border-radius: 25px !important;
    height: 50px;
    color: var(--CLUBBIE-WHITE) !important;
    font-family: var(--PROXIMA-NOVA-REGULAR);
}

.email-input::placeholder {
    color: var(--CLUBBIE-WHITE) !important;
    font-family: var(--PROXIMA-NOVA-REGULAR);
}

.email-input:focus {
    box-shadow: none !important;
}

.password-input {
    position: relative;
    width: 350px;
}

.password-toggle-button {
    position: absolute;
    top: 25%;
    right: 2%;
    transform: translateY(-50%);
    background: none !important;
    border: none !important;
    cursor: pointer;
    font-size: 1.25rem !important;
    color: #6c757d;
  }

.password-toggle-button:hover {
    opacity: 0.5;
}

.info-warning {
    font-size: 1rem;
    font-family: var(--ProximaNovaSemibold);
    color: var(--CORAL-RED);
}

.auth-top-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 40px;
}

.back-button {
    width: 2%;
    align-self: flex-start;
}

.back-button:hover {
    opacity: 0.5;
}

@media (max-width: 768px) {
    .back-button {
        width: 5%
    }
}

@media (max-width: 320px) {
    .password-input {
        width: 300px;
    }

    .email-input {
        width: 300px !important;
    }
    .register-button {
        width: 300px;
    }
}

