@import '../../utils/styles.css';


.app-container-landing {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: var(--APP-BACKGROUND);
    padding-top: 37px;
    overflow: hidden;
}

.app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: transparent;
    padding-top: 37px;
    z-index: 1;
}

#background-video {
    position: absolute;
    width: 100vw;  
    height: 100vh; 
    object-fit: cover;
}

.auth-top-bar-first-page{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 40px;
}

.clubbie-logo {
    width: 20%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.welcome-text-box {
    margin-top: 100px;
    width: 55%
}

.welcome-text {
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    color: var(--CLUBBIE-WHITE);
    text-align: center;
    font-size: 100px;
}

.welcome-msg-landing {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-align: center;
    font-size: 15px;
}

.button-container-first-page {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.login-button {
    background-color: transparent;
    border: 1px solid;
    border-color: var(--LIGHT-BLUE);
    border-radius: 30px;
    height: 50px;
    width: 350px;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    color: var(--LIGHT-BLUE);
    text-align: center;
    font-size: 15px;
}

.login-button:hover {
    opacity: 0.5;
}

.studio-button {
    background-color: var(--LIGHT-BLUE);
    border: 0;
    border-radius: 30px;
    height: 50px;
    width: 350px;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    color: var(--CLUBBIE-BLACK);
    text-align: center;
    font-size: 15px;
    margin-top: 50px;
}

.studio-button:hover{
    opacity: 0.5;
}


@media (max-width: 768px) {
    .clubbie-logo {
      width: 60%;
    }

    .welcome-text-box {
        width: 40%;
    }

    #background-video {
        width: 100vw;  
        height: 100vh; 
        object-fit: cover;
        overflow: hidden;
    }
}

