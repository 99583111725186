@import '../utils/styles.css';


.item-options{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saved-item-button{
    height: 40px !important;
    font-size: 1.1rem !important;
    font-family: var(--PROXIMA-NOVA-BOLD) !important;
    border-radius: 8px !important;
    background-color: var(--LIGHT-BLUE) !important;
    color: var(--CLUBBIE-BLACK) !important;
    width: 150px !important;
    margin-bottom: 10px;
    border: 0;
    padding-top: 7px !important;
}

.saved-item-button:hover{
    opacity: 0.5
}

.saved-item-delete{
    height: 40px !important;
    color: var(--CORAL-RED) !important;
    margin-top: 5px;
    border: 0;
    cursor: pointer;
}

.saved-item-delete:hover{
    opacity: 0.5;
}


