@import '../utils/styles.css';

.pop-button{
    height: 55px !important;
    font-size: 1.5rem !important;
    font-family: var(--PROXIMA-NOVA-BOLD) !important;
    border: 1px solid var(--LIGHT-BLUE) !important;
    border-radius: 8px !important;
    background-color: transparent !important;
    color: var(--LIGHT-BLUE) !important;
    margin-top: 20px !important;
    text-transform: uppercase;
}

.pop-button.delete{
    border: 1px solid var(--CORAL-RED) !important;
    color: var(--CORAL-RED) !important;
}

.pop-button:hover{
    border: 1px solid var(--LIGHT-BLUE) !important;
    background-color: var(--LIGHT-BLUE) !important;
    color: var(--CLUBBIE-BLACK) !important;
}

.pop-button.delete:hover{
    border: 1px solid var(--CORAL-RED) !important;
    background-color: var(--CORAL-RED) !important;
    color: var(--CLUBBIE-BLACK) !important;
}

@media (max-width: 425px) {
    .pop-button {
        width: 40% !important;
    }
}