@import '../utils/styles.css';

.selection-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--APP-BACKGROUND);
    align-items: center;
}

.selection-top-bar {
    display: flex;
    justify-content: center !important; 
    align-items: center;
    width: 100%;
    transition: justify-content 0.6s ease-in;
    position: relative;
}

.selection-page-heading {
    font-family: var(--HEATERS);
    font-size: 130px;
    color: var(--LIGHT-BLUE);
    margin-top: 80px;
    line-height: 0%;
}
 
.options-holder {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in;
} 

.options.slide-out {
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.options.slide-in {
    transform: translateX(100%);
    transition: transform 0.01s ease-in-out;
}

.custom-design-note {
    color: var(--CLUBBIE-WHITE);
    font-size: 0.8rem;
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    max-width: 50%; 
    text-align: center;
    justify-content: center;
    margin-top: 130px;
}


.socials-icons  {
    display: flex;
    gap: 20px;
}


.underline-email {
    text-decoration: underline;
}

@media (max-width:768px) {
    .selection-page-heading {
        font-size: 80px !important;
    }

    .options-holder {
        margin-top: 0px;
    }

    .custom-design-note {
        max-width: 80%; 
        margin-top: 80px;
    }
}



