@import '../utils/styles.css';

.input-row {
    margin-bottom: 5px;
    background-color: var(--BLUE-SHADE-5);
}

.color-option {
    border: 0.5px dotted silver;
    float: left;
    margin: 2px;
    width: 20px;
    height: 20px;
}

.color-option.selected {
    border: 1.5px solid var(--LIGHT-BLUE);
}

.input-heading {
    color: var(--CLUBBIE-WHITE); 
    font-size: 2rem; 
    line-height: 30px;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.input-text {
    color: var(--CLUBBIE-WHITE);
    font-size: 0.9rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.input-button {
    display: flex;
    height: 54px;
    width: 54px;
    border-radius: 10px;
    border: 1px solid;
    justify-content: center;
    align-items: center;
}

.input-icon {
    width: 30px;
    height: 30px;
}

.input-button-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}



@media (max-width: 768px) {
    .input-heading-box {
        width: 60% !important;
    }

    .input-button-box {
        width: 40% !important;
    }
    
    .input-row {
        display: flex;
        justify-content: center;
    }
}

