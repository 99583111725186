@import '../utils/styles.css';

.order-pop-up {
    position: fixed; 
    margin: 0px; 
    padding: 0px; 
    top: 35%;
    left: 50%;
    width: 50vw; 
    height: 65vh;
    z-index: 1000;
    transform: translate(-50%, -50%);
}

.order-pop-up-banner {
    padding: 5px;
    background: var(--BLUE-SHADE-6);
    border: 4px solid var(--CLUBBIE-YELLOW);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    
}

.order-pop-up-msg {
    font-size: 2rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    color: var(--LIGHT-BLUE);
    width: 80%;
    text-align: center;
}

.order-input-fields {
    margin-top: 10px;
    width: 600px;
}

.order-input-heading {
    font-size: 1.8rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    color: var(--LIGHT-BLUE);
    text-align: left;
}

.instruction-field {
    height: 100px;
    white-space: normal; 
    overflow-wrap: break-word;
}

.email-warning {
    font-size: 1rem;
    font-family: var(--ProximaNovaSemibold);
    color: var(--CORAL-RED);
    text-align: left;
}

@media (max-width: 768px) {
    .order-pop-up {
        width: 90%;
    }

    .order-input-fields {
        margin-top: 10px;
        width: 80%;
    }

    
}
