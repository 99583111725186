@import '../../utils/styles.css';


.react-tel-input .form-control:hover {
    background-color: transparent !important; /* Change color on hover */
}

.react-tel-input .selected-flag {
    background-color: transparent !important; 
}

.react-tel-input .country.highlight {
    background-color: var(--LIGHT-BLUE) !important;
    color: var(--CLUBBIE-BLACK) !important;
}