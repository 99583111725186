@import '../../utils/styles.css';

.verification-code {
    display: flex;
    gap: 8px;
}

.code-slot {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border-radius: 4px;
    border: 1px solid var(--CLUBBIE-WHITE);
    background-color: transparent;
    color: var(--CLUBBIE-WHITE);
}

.code-slot:focus {
    outline: none;
}

.resend-button {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-decoration: underline;
    border: none;
    background: none;
}

.resend-button:disabled {
    color: var(--SHADED-TEXT);
}