@import '../utils/styles.css';

.general-pop-up {
    position: fixed; 
    margin: 0px; 
    padding: 0px; 
    top: 70%;
    left: 50%;
    min-width: 50vw;
    min-height: 100vh;
    overflow: auto;
    z-index: 1000;
    transform: translate(-50%, -50%);
   
}

.general-pop-up-banner {
    padding: 5px;
    background: var(--BLUE-SHADE-6);
    border: 4px solid var(--CLUBBIE-YELLOW);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 50px;
}

.pop-up-msg {
    font-size: 2rem;
    font-family: var(--PROXIMA-NOVA-BOLD);
    color: var(--LIGHT-BLUE);
    width: 500px;
    text-align: center;
}

.fail-msg {
    color: var(--CORAL-RED);
}

@media (max-width: 425px) {
    .general-pop-up {
        width: 80%;
    }

    .pop-up-msg {
        width: 80%
    }
}