@import '../../utils/styles.css';

.terms-and-conditions {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--APP-BACKGROUND);
    padding-left: 10px;
    color: var(--CLUBBIE-WHITE);
    font-family: var(--PROXIMA-NOVA-REGULAR);

}

.section-title {
    font-family: var(--PROXIMA-NOVA-BOLD);
}

