@import '../utils/styles.css';

.size-pop-up-bg {
    background-color: var(--APP-BACKGROUND) !important;
    color: var(--CLUBBIE-WHITE);
    font-family: var(--PROXIMA-NOVA-BOLD);
}

.sub-item-heading {
    display: flex;
    justify-content:space-between
}

.add-info-button {
    font-size: 1rem !important;
    font-family: var(--PROXIMA-NOVA-BOLD) !important;
    border-radius: 8px !important;
    background-color: var(--LIGHT-BLUE) !important;
    color: var(--CLUBBIE-BLACK) !important;
    padding-top: 10px !important;
    margin-bottom: 10px;
    border: 0;
}




