@import '../utils/styles.css';


.size-chart-modal {
    display: flex;
    justify-content: right;
    align-items: center;
  }

.size-chart {
    position: fixed; 
    margin: 0px; 
    padding: 0px; 
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    width: 70%;
}

@media (max-width: 768px) {
  .size-chart {
    width: 90%;
  }
}
