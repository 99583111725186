@import '../../utils/styles.css';


.app-container-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--APP-BACKGROUND);
    padding-top: 50px;
    overflow: hidden;
    height: 100vh;

  }

.clubbie-logo {
    width: 20%;
}

.welcome-text-box {
    margin-top: 80px;
}

.welcome-text {
    font-family: var(--PROXIMA-NOVA-SEMIBOLD);
    color: var(--CLUBBIE-WHITE);
    text-align: center;
    font-size: 30px;
}

.welcome-msg {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--SHADED-TEXT);
    text-align: center;
    font-size: 15px;
}

.button-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
}

.login-option-button {
    background-color: var(--BLUE-SHADE-5) !important;
    display: flex;
    border-radius: 30px !important;
    border: 0 !important;
    height: 45px;
    width: 350px;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding-top: 10px; 
    
}

.login-option-text {
    text-align: center;
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    font-size: 16px;
    flex-grow: 1;
}


.login-option-button:hover {
    opacity: 0.5;
    /* background-color: var(--BLUE-SHADE-5); */
}

.login-failed-msg {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CORAL-RED);
    text-align: center;
    font-size: 15px;
}

.go-login-msg {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-align: center;
    font-size: 15px;
    margin-top: 20px;
}

.go-login-button {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-decoration: underline;
    border: none;
    background: none;
}

.terms-msg {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--SHADED-TEXT);
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
}

.terms-button {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--BLUE-SHADE-2);
    border: none;
    background: none;
}


@media (max-width: 767px) {
    .clubbie-logo {
      width: 60%;
    }

    .welcome-text-box {
        width: 80%;
    }
}

@media (max-width: 320px) {
    .login-option-button {
        width: 300px;
    }
}



