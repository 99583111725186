@import '../utils/styles.css';

.dropzone {
    min-height: 50px;
    border: 2px dashed silver;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
}

.uploaded-image {
    max-width: 50px;
}

.upload-image-text {
    text-align: center;
    padding: 14px;
    margin: 0px;
    cursor: pointer
}

