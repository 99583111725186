@import '../../utils/styles.css';


.go-register-msg {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-align: center;
    font-size: 15px;
    margin-top: 25px;
}

.go-register-button {
    font-family: var(--PROXIMA-NOVA-REGULAR);
    color: var(--CLUBBIE-WHITE);
    text-decoration: underline;
    border: none;
    background: none;
}





