@import '../utils/styles.css';


.selection-heading {
  font-family: var(--PROXIMA-NOVA-BOLD);
  font-size: 50px;
  color: var(--CLUBBIE-WHITE);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

@media (max-width:786px) {
    .selection-heading {
        font-size: 40px;
    }  
}
.option-set {
    display: inline-block;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
.options {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .option-button {
    background-color: transparent; 
    border: 1px solid;
    color: var(--LIGHT-BLUE);
    padding-top: 4px;
    border-radius: 45px;
    height: 70px;
    width: 420px;
    font-family: var(--PROXIMA-NOVA-BOLD);
    font-size: 25px;
    margin-bottom: 20px;
  }

  .option-button:hover {
    opacity: 0.5;
  }

@media (max-width:425px) {
    .option-button {
        width: 320px !important;
    }

    
}
  